/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React, { useState,useRef } from 'react';
//import { useAppContext } from "../libs/contextLib";
import withWrapper from '../components/wrapper.js';
import { navigate } from "gatsby";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from '../aws-exports';
import { Helmet } from "react-helmet";

Amplify.configure(awsconfig);


function _inner (props) {
//  const { userHasAuthenticated } = useAppContext();
  const [email, setEmail] = useState("");

  function validateForm() {
    let val=email.length>3;
    return val;
  }
  
  function setIsLoading() {
      
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.forgotPassword(email);
      props.setText("Email sent");
      navigate("/resetPassword?email="+email);
    } catch (e) {
      props.setText("Failure: "+e.message);
      setIsLoading(false);
    }
  }
  let topstyle={position: 'relative',
    width: '100%',
  };
  
  let innerStyle={
    
  };
  
  return (
    <div className="LoginForm" style={topstyle} >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Forgot Password</title>
        <link rel="canonical" href="https://www.valtrace.com/forgotPassword/" />
        <html lang="en" />
      </Helmet>

      <div style={innerStyle} >
        <style>{`
          .LoginForm input {
            border-radius: 2px;
            border: 1px solid #aaa;
            padding: 2px;
          }
        `}
        </style>
          <form onSubmit={handleSubmit}>
            <div style={{padding:'15px 0px', textAlign:'center'}} className="form-group">
                <input placeholder="Email" autoFocus style={{marginLeft:'15px',width:'15em'}} type="email" className="form-control Hip" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div style={{clear:'both',padding:'5px 0px',width:'100%', textAlign:'center'}}>
              <button type="submit" className='btn' disabled={!validateForm()} >Reset Password</button>
            </div>
          </form>
        </div>
    </div>
  );
}

export default withWrapper(_inner);